import React, { useContext, useState } from 'react';
import { withAdminLayout } from '../../components/HOC/WithLayout';
import { I18nContext } from 'react-i18next';
import SecondaryNavigation from '../../components/SecondaryNavigation/SecondaryNavigation';
import MainTitle from '../../components/MainTitle/MainTitle';
import LastPaymentsTable from '../../components/LastPaymentsTable/LastPaymentsTable';
import DateChooser from '../../components/DateChooser/DateChooser';
import { STATS_DATES } from '../../utils/app-constants';
import SubTotalItem from '../../components/SubTotalItem/SubTotalItem';
import PaymentsChart from '../../components/PaymentsChart/PaymentsChart';
import PaymentsGrandTotal from '../../components/PaymentsGrandTotal/PaymentsGrandTotal';
import './payments-page.scss';

const PaymentsPage = props => {
    const {i18n} = useContext(I18nContext);
    const [chosenDate, setDate] = useState(STATS_DATES.LAST);

    return (
        <section className="payments-page">
            <div className="payments-page__navigation">
                <SecondaryNavigation />
            </div>
            <div className="payments-page__header">
                <MainTitle>
                    {i18n.t('pages.payments.title')}
                </MainTitle>
            </div>
            <div className="payments-page__grand-totals">
                <PaymentsGrandTotal />
            </div>
            <div className="payments-page__sub-totals">
                <SubTotalItem title={i18n.t('components.sub_total_item.total_adions_incomes.title')}
                              value={248.48}
                              description={i18n.t('components.sub_total_item.total_adions_incomes.description')}
                />
                <SubTotalItem title={i18n.t('components.sub_total_item.total_adions_incomes.title')}
                              value={248.48}
                              description={i18n.t('components.sub_total_item.total_adions_incomes.description')}
                />
                <SubTotalItem title={i18n.t('components.sub_total_item.total_adions_incomes.title')}
                              value={248.48}
                              description={i18n.t('components.sub_total_item.total_adions_incomes.description')}
                />
                <SubTotalItem title={i18n.t('components.sub_total_item.total_adions_incomes.title')}
                              value={248.48}
                              description={i18n.t('components.sub_total_item.total_adions_incomes.description')}
                />
                <SubTotalItem title={i18n.t('components.sub_total_item.total_adions_incomes.title')}
                              value={248.48}
                              description={i18n.t('components.sub_total_item.total_adions_incomes.description')}
                />
                <SubTotalItem title={i18n.t('components.sub_total_item.total_adions_incomes.title')}
                              value={248.48}
                              description={i18n.t('components.sub_total_item.total_adions_incomes.description')}
                />
            </div>
            <div className="payments-page__last-payments">
                <DateChooser chosen={chosenDate}
                             onChoose={setDate}
                />
                <LastPaymentsTable />
            </div>
            <div className="payments-page__chart">
                <PaymentsChart />
            </div>
        </section>
    );
};

export default withAdminLayout(PaymentsPage);
