import React, { useContext, useState } from 'react';
import { withAdminLayout } from '../../components/HOC/WithLayout';
import SecondaryNavigation from '../../components/SecondaryNavigation/SecondaryNavigation';
import MainTitle from '../../components/MainTitle/MainTitle';
import { I18nContext } from 'react-i18next';
import DateChooser from '../../components/DateChooser/DateChooser';
import { STATS_DATES, STATS_KPIS, STREAMING_PLATFORMS } from '../../utils/app-constants';
import PlatformToggler from '../../components/PlatformToggler/PlatformToggler';
import StatsKPI from '../../components/StatsKPI/StatsKPI';
import StatsCard from '../../components/StatsCard/StatsCard';
import './my-stats-page.scss';

const MyStatsPage = props => {
    const {i18n} = useContext(I18nContext);
    const [chosenDate, setDate] = useState(STATS_DATES.LAST);
    const [platform, setPlatform] = useState(STREAMING_PLATFORMS.TWITCH);

    const togglePlatform = (platformName, checked) => {
        const currentPlatform = checked ? platformName : null;
        setPlatform(currentPlatform);
    };

    return (
        <section className="my-stats-page">
            <div className="my-stats-page__navigation">
                <SecondaryNavigation />
            </div>
            <div className="my-stats-page__header">
                <MainTitle>
                    {i18n.t('pages.my_stats.title')}
                </MainTitle>
                <DateChooser chosen={chosenDate}
                             onChoose={setDate}
                />
                <PlatformToggler className="my-stats-page__platforms"
                                 checked={platform}
                                 bare
                                 //onToggle={togglePlatform}
                />
            </div>
            <div className="my-stats-page__col1">
                <StatsKPI value={396}
                          type={STATS_KPIS.VIEWERS}
                          delta={14}
                />
                <StatsKPI value={57}
                          type={STATS_KPIS.OTHER}
                          delta={9}
                />
                <StatsKPI value={57}
                          type={STATS_KPIS.OTHER}
                />
                <StatsKPI value={57}
                          type={STATS_KPIS.SUBS}
                          delta={0}
                />
                <StatsKPI value={57}
                          type={STATS_KPIS.OTHER}
                          delta={9}
                />
                <StatsKPI value={27}
                          type={STATS_KPIS.LAUNCHED_ADIONS}
                          delta={2}
                />
            </div>
            <div className="my-stats-page__col2">
                <StatsKPI value={57}
                          type={STATS_KPIS.FIDELITY}
                          delta={9}
                />
                <StatsKPI value={57}
                          type={STATS_KPIS.OTHER}
                          delta={9}
                />
                <StatsKPI value={57}
                          type={STATS_KPIS.OTHER}
                          delta={-2}
                />
            </div>
            <div className="my-stats-page__col3">
                <StatsCard title={i18n.t('Adions incomes')}
                           description={i18n.t('Last Streaming Incomes')}
                           value={8.42}
                           delta={1.26}
                />
                <StatsCard description={i18n.t('Average Price/Adion')}
                           value={0.31}
                           delta={0.09}
                />
            </div>
        </section>
    );
};

export default withAdminLayout(MyStatsPage);
