import React from 'react';
import './secondary-title.scss';

const SecondaryTitle = props => {
    const getClassName = props => {
        const classNames = ['secondary-title'];

        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return <h3 className={getClassName(props)}>{props.children}</h3>;
};

export default SecondaryTitle;
