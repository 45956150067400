import React, { useContext } from 'react';
import { I18nContext } from 'react-i18next';
import PropTypes from 'prop-types';
import { STATS_KPIS } from '../../utils/app-constants';
import NumberFormat from '../../NumberFormat/NumberFormat';
import Icon from '../Icon/Icon';
import './stats-kpi.scss';

const StatsKPI = props => {
    const {i18n} = useContext(I18nContext);

    const getClassName = props => {
        const classNames = ['stats-kpi'];

        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    const getDeltaIcon = delta => {
        let icon = '';

        // TODO: Otras condiciones según business logic
        if (delta < 0) icon = 'arrow-down';
        if (delta > 0) icon = 'arrow-up';
        if (!delta) icon = 'equals';

        return icon;
    };

    return (
        <div className={getClassName(props)}>
            <div className="stats-kpi__big-box">
                <div className="stats-kpi__value">
                    {props.value}
                </div>
                <div className="stats-kpi__description">
                    {i18n.t(`components.stats_kpi.${props.type}`)}
                </div>
            </div>
            <div className="stats-kpi__delta-box">
                <div className="stats-kpi__delta-icon">
                    <Icon icon={getDeltaIcon(props.delta)} />
                </div>
                <NumberFormat value={props.delta}
                              prefix={props.delta > 0 ? '+' : null}
                />
            </div>
        </div>
    );
};

export default StatsKPI;

StatsKPI.defaultProps = {
    type: STATS_KPIS.OTHER,
    value: 0,
    delta: 0
};

StatsKPI.propTypes = {
    type: PropTypes.oneOf([STATS_KPIS.VIEWERS, STATS_KPIS.SUBS, STATS_KPIS.FIDELITY, STATS_KPIS.LAUNCHED_ADIONS, STATS_KPIS.OTHER])
};
