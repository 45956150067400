import React from 'react';
import TwitchButton from '../PlatformButton/TwitchButton';
import { STREAMING_PLATFORMS } from '../../utils/app-constants';
import YoutubeButton from '../PlatformButton/YoutubeButton';
import MixerButton from '../PlatformButton/MixerButton';
import FacebookButton from '../PlatformButton/FacebookGamingButton';

const PlatformToggler = props => {
    const getClassName = props => {
        const classNames = ['platform-chooser'];

        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return (
        <div className={getClassName(props)}>
            <TwitchButton bare={props.bare}
                          checked={props.checked === STREAMING_PLATFORMS.TWITCH}
                          onClick={checked => props.onToggle(STREAMING_PLATFORMS.TWITCH, checked)}
            />
            <YoutubeButton bare={props.bare}
                           checked={props.checked === STREAMING_PLATFORMS.YOUTUBE}
                           onClick={checked => props.onToggle(STREAMING_PLATFORMS.YOUTUBE, checked)}
            />
            <MixerButton bare={props.bare}
                         checked={props.checked === STREAMING_PLATFORMS.MIXER}
                         onClick={checked => props.onToggle(STREAMING_PLATFORMS.MIXER, checked)}
            />
            <FacebookButton bare={props.bare}
                            checked={props.checked === STREAMING_PLATFORMS.FACEBOOK_GAMING}
                            onClick={checked => props.onToggle(STREAMING_PLATFORMS.FACEBOOK_GAMING, checked)}
            />
        </div>
    );
};

export default PlatformToggler;

PlatformToggler.defaultProps = {
    onToggle: () => {}
};
