import React from 'react';
import { withAdminLayout } from '../../components/HOC/WithLayout';
import StreamScoreWidget from '../../components/StreamScoreWidget/StreamScoreWidget';
import LiveModeCard from '../../components/LiveModeCard/LiveModeCard';
import CheckOverlayCard from '../../components/CheckOverlayCard/CheckOverlayCard';
import SecondaryNavigation from '../../components/SecondaryNavigation/SecondaryNavigation';
import './live-mode-setup-page.scss';

const LiveModeSetupPage = props => (
    <section className="live-mode-setup-page">
        <div className="live-mode-setup-page__navigation">
            <SecondaryNavigation />
        </div>
        <div className="live-mode-setup-page__header">
            <StreamScoreWidget />
        </div>
        <div className="live-mode-setup-page__col1">
            <LiveModeCard />
        </div>
        <div className="live-mode-setup-page__col2">
            <CheckOverlayCard />
        </div>
    </section>
);

export default withAdminLayout(LiveModeSetupPage);
