export const STREAMING_PLATFORMS = {
    TWITCH: 'twitch',
    YOUTUBE: 'youtube',
    MIXER: 'mixer',
    FACEBOOK_GAMING: 'facebookg_gaming'
};

export const STATS_DATES = {
    LAST: 'last',
    SEVEN_DAYS: '7',
    FOURTEEN_DAYS: '14',
    THIRTY_DAYS: '30',
    CUSTOM: 'custom'
};

export const STATS_KPIS = {
    VIEWERS: 'viewers',
    SUBS: 'subs',
    FIDELITY: 'fidelity',
    LAUNCHED_ADIONS: 'launched_adions',
    OTHER: 'typed_adions'
};
