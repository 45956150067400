import React from 'react';
import PlatformButton from './PlatformButton';
import logo from './mixer.svg';

const MixerButton = props => (
    <PlatformButton logo={logo}
                    bare={props.bare}
                    checked={props.checked}
                    onClick={props.onClick}
    />
);

export default MixerButton;
