import React, { useContext, useState } from 'react';
import NumberFormat from '../../NumberFormat/NumberFormat';
import Button from '../Button/Button';
import { I18nContext } from 'react-i18next';
import Modal from '../Modal/Modal';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import './payments-grand-total.scss';
import Portal from '../Portal/Portal';

const PaymentsGrandTotal = props => {
    const [modalIsOpen, setModalOpen] = useState(false);
    const {i18n} = useContext(I18nContext);

    return (
        <div className="payments-grand-total">
            <SecondaryTitle className="mb-3">
                {i18n.t('components.payments_grand_total.title')}
            </SecondaryTitle>
            <NumberFormat className="payments-grand-total__value"
                          value={67.97}
                          suffix="€"
            />
            <Button text={i18n.t('components.payments_grand_total.action')}
                    solid
                    onClick={event => setModalOpen(true)}
            />
            <Modal open={modalIsOpen}
                   title={i18n.t('components.payments_grand_total.modal.withdraw_money.title')}
                   onClose={event => setModalOpen(false)}>
                Contenido del modal
                <Portal.In target="modal__actions">
                    <Button text={i18n.t('components.payments_grand_total.modal.withdraw_money.button.cancel')}
                            onClick={event => setModalOpen(false)}
                    />
                    <Button text={i18n.t('components.payments_grand_total.modal.withdraw_money.button.accept')}
                            solid
                            onClick={event => console.log('Botón desde el modal')}
                    />
                </Portal.In>
            </Modal>
        </div>
    );
};

export default PaymentsGrandTotal;
