import React, { useContext } from 'react';
import { I18nContext } from 'react-i18next';
import PropTypes from 'prop-types';
import { STATS_DATES } from '../../utils/app-constants';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import Pickadate from 'pickadate/builds/react-dom';
import Icon from '../Icon/Icon';
import './date-chooser.scss';

const DateChooser = props => {
    const {i18n} = useContext(I18nContext);

    const getItemClassName = isChosen => {
        return isChosen
            ? 'date-chooser__item date-chooser__item--is-chosen'
            : 'date-chooser__item';
    };

    return (
        <div className="date-chooser">
            <div className={getItemClassName(props.chosen === STATS_DATES.LAST)}
                 onClick={event => props.onChoose(STATS_DATES.LAST)}>
                {i18n.t('components.date_chooser.last')}
            </div>
            <div className={getItemClassName(props.chosen === STATS_DATES.SEVEN_DAYS)}
                 onClick={event => props.onChoose(STATS_DATES.SEVEN_DAYS)}>
                {i18n.t('components.date_chooser.7_days')}
            </div>
            <div className={getItemClassName(props.chosen === STATS_DATES.THIRTY_DAYS)}
                 onClick={event => props.onChoose(STATS_DATES.THIRTY_DAYS)}>
                {i18n.t('components.date_chooser.30_days')}
            </div>
            <div className={getItemClassName(props.chosen === STATS_DATES.CUSTOM)}
                 onClick={event => props.onChoose(STATS_DATES.CUSTOM)}>
                {i18n.t('components.date_chooser.custom')}
                <ContextualMenu id="custom-date-chooser"
                                bare
                                triggerComponent={<Icon icon="calendar-alt" />}
                                menuItems={[
                                    {
                                        props: {
                                            preventClose: true
                                        },
                                        component: <Pickadate.DatePicker onChangeValue={({value, date}) => {
                                            console.log('value', value);
                                            console.log('data', date)
                                        }}
                                        />
                                    }
                                ]}
                />
            </div>
        </div>
    );
};

export default DateChooser;

DateChooser.defaultProps = {
    onChoose: () => {}
};

DateChooser.propTypes = {
    chosen: PropTypes.oneOf([
        STATS_DATES.LAST,
        STATS_DATES.SEVEN_DAYS,
        STATS_DATES.FOURTEEN_DAYS,
        STATS_DATES.THIRTY_DAYS,
        STATS_DATES.CUSTOM
    ])
};
