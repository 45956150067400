import React from 'react';
import NumberFormat from '../../NumberFormat/NumberFormat';
import './sub-total-item.scss';

const SubTotalItem = props => {
    return (
        <article className="sub-total-item">
            <header className="sub-total-item__header">
                <h1 className="sub-total-item__title">{props.title}</h1>
            </header>
            <div className="sub-total-item__contents">
                <NumberFormat className="sub-total-item__value"
                              value={props.value}
                              suffix="€"
                />
                {!!props.description &&
                <div className="sub-total-item__description">
                    {props.description}
                </div>}
            </div>
        </article>
    );
};

export default SubTotalItem;
