import React from 'react';
import ReactNumberFormat from "react-number-format";

const NumberFormat = props => {
    const {className, ...rest} = props;

    return (
        <ReactNumberFormat {...rest}
                           displayType="text"
                           decimalSeparator=","
                           renderText={formattedValue => (
                               <span className={props.className ? props.className : 'number-format'}>{formattedValue}</span>
                           )}
        />
    );
};

export default NumberFormat;
