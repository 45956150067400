import React from 'react';
import { useField } from 'formik';
import './form-field.scss';

const InputField = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    const getFormFieldClassName = props => {
        const classNames = ['form-field'];

        if (meta.touched && meta.error) classNames.push('form-field--is-invalid');

        return classNames.join(' ');
    };

    return (
        <div className={getFormFieldClassName(meta)}>
            <label className="form-field__label"
                   htmlFor={props.id || props.name}>
                {label}
            </label>
            <input className="form-field__control"
                   {...field}
                   {...props}
            />
            {meta.touched && meta.error
                ? <div className="form-field__error">
                    {meta.error}
                </div>
            : null}
        </div>
    );
};
export default InputField;
