import React from 'react';
import { Link } from 'react-router-dom';
import './button.scss';

const Button = props => {
    const getClassName = props => {
        const classNames = ['button'];

        if (props.primary) classNames.push('button--primary');
        if (props.secondary) classNames.push('button--secondary');
        if (props.light) classNames.push('button--light');
        if (props.dark) classNames.push('button--dark');
        if (props.small) classNames.push('button--small');
        if (props.solid) classNames.push('button--solid');
        if (props.gradient) classNames.push('button--gradient');
        if (props.block) classNames.push('button--block');
        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return (
        props.to
            ? <Link className={getClassName(props)}
                    to={props.to}>
                <div className="button-text">{props.text}</div>
            </Link>
            : <button className={getClassName(props)}
                      type={props.type}
                      disabled={props.disabled}
                      onClick={props.onClick}>
                <div className="button-text">{props.text}</div>
            </button>
    );
};

export default Button;

Button.defaultProps = {
    type: 'button',
    text: '',
    onClick: () => {}
};
