import React from 'react';
import TickCircle from '../TickCircle/TickCircle';
import './platform-button.scss';

const PlatformButton = props => {
    const getClassName = props => {
        const classNames = ['platform-button'];

        if (props.checked) classNames.push('platform-button--is-checked');
        if (props.bare) classNames.push('platform-button--is-bare');

        return classNames.join(' ');
    };

    return (
        <div {...props}
             className={getClassName(props)}
             onClick={event => props.onClick(!props.checked)}>
            {props.logo
                ? <img src={props.logo} alt={props.name} />
                : props.name
            }
            {!!props.checked &&
            <TickCircle className="platform-button__tick"
                        checked={props.checked}
            />}
        </div>
    );
};

export default PlatformButton;

PlatformButton.defaultProps = {
    name: '',
    onClick: () => {}
};
