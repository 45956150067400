import React, { useContext, useState } from 'react';
import { I18nContext } from 'react-i18next';
import { withLoginManagementLayout } from '../../components/HOC/WithLoginManagementLayout';
import MainTitle from '../../components/MainTitle/MainTitle';
import './change-password-page.scss';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';

const ChangePasswordPage = props => {
    const [isSubmitted, setSubmitted] = useState(false);
    const {i18n} = useContext(I18nContext);

    const handleSubmit = ({password}) => {
        setSubmitted(true);
    };

    const renderPreRecoverBlock = () => {
        return (
            <div className="change-password-page__pre-change-block">
                <ChangePasswordForm onSubmit={handleSubmit} />
            </div>
        );
    };

    const renderPostRecoverBlock = () => {
        return (
            <div className="change-password-page__post-change-block">
                {i18n.t('pages.change_password.post_change_instructions')}
            </div>
        );
    };

    return (
        <section className="change-password-page">
            <MainTitle>{i18n.t('pages.change_password.title')}</MainTitle>
            {isSubmitted
                ? renderPostRecoverBlock()
                : renderPreRecoverBlock()
            }
        </section>
    );
};

export default withLoginManagementLayout(ChangePasswordPage);
