import React from 'react';
import { useTable, useSortBy } from 'react-table';
import './table.scss';
import Icon from '../Icon/Icon';

const Table = props => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            data: props.data,
            columns: props.columns,
        },
        useSortBy
    );

    const getClassName = props => {
        const classNames = ['table'];

        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return (
        <table className={getClassName(props)} {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span className="table__sorting">
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? <Icon icon="caret-down" />
                                            : <Icon icon="caret-up" />
                                        : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className={row.original.className}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>
                                    <div className="table__cell">
                                        {cell.render('Cell')}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    )}
                )}
            </tbody>
        </table>
    );
};

export default Table;
