import React from 'react';
import MockService from '../../services/MockService';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    const mockService = new MockService();

    return (
        <Route {...rest}
               render={props => (
                   mockService.loggedIn()
                       ? <Component {...props} user={mockService.decodeToken()} />
                       : <Redirect to={{pathname: '/login', state: {from: props.location}}} />
               )}
        />
    );
};

export default PrivateRoute;
