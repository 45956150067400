import React from 'react';
import PrimaryNavigation from '../../PrimaryNavigation/PrimaryNavigation';
import './admin-layout.scss';
import Container from '../../Container/Container';

const AdminLayout = props => (
    <div className="admin-layout">
        <aside className="admin-layout__sidebar">
            <Container narrow>
                <PrimaryNavigation />
            </Container>
        </aside>
        <main className="admin-layout__main">
            <Container>
                {props.children}
            </Container>
        </main>
        <aside className="admin-layout__sidebar">
            <Container narrow>
            </Container>
        </aside>
    </div>
);

export default AdminLayout;
