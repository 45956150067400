import React from 'react';
import Container from '../../Container/Container';
import StreamionGradientBrand from '../../StreamionGradientBrand/StreamionGradientBrand';
import './login-management-layout.scss';

const LoginManagementLayout = props => (
    <div className="login-management-layout">
        <main className="login-management-layout__main">
            <Container>
                <StreamionGradientBrand />
                {props.children}
            </Container>
        </main>
    </div>
);

export default LoginManagementLayout;
