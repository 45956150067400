import React from 'react';
import './tick-circle.scss';

const TickCircle = props => {
    const getClassName = props => {
        const classNames = ['tick-circle'];

        if (props.checked) classNames.push('tick-circle--is-checked');
        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return <div className={getClassName(props)} />
};

export default TickCircle;
