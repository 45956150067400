import React, { useContext } from 'react';
import { I18nContext } from 'react-i18next';
import { withLoginManagementLayout } from '../../components/HOC/WithLoginManagementLayout';
import './sign-up-page.scss';

const SignUpPage = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <section>
            Sign Up page
        </section>
    );
};

export default withLoginManagementLayout(SignUpPage);
