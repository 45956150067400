import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/HOC/PrivateRoute';
import LoginPage from '../pages/LoginPage/LoginPage';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import UserAccountPage from '../pages/UserAccountPage/UserAccountPage';
import LiveModeSetupPage from '../pages/LiveModeSetupPage/LiveModeSetupPage';
import MyStatsPage from '../pages/MyStatsPage/MyStatsPage';
import PaymentsPage from '../pages/PaymentsPage/PaymentsPage';
import Error404Page from '../pages/Error404Page/Error404Page';
import RecoverPasswordPage from '../pages/RecoverPasswordPage/RecoverPassword';
import SignUpPage from '../pages/SignUpPage/SignUpPage';
import ChangePasswordPage from '../pages/ChangePasswordPage/ChangePasswordPage';

export default () => (
    <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/recover-password" component={RecoverPasswordPage} />
        <Route path="/change-password" component={ChangePasswordPage} />
        <Route path="/sign-up" component={SignUpPage} />
        <PrivateRoute path="/" exact component={DashboardPage} />
        <PrivateRoute path="/user-account" exact component={UserAccountPage} />
        <PrivateRoute path="/live-mode/setup" exact component={LiveModeSetupPage} />
        <PrivateRoute path="/my-stats" exact component={MyStatsPage} />
        <PrivateRoute path="/payments" exact component={PaymentsPage} />
        <Route component={Error404Page} />
    </Switch>
);
