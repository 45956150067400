import BaseService from './BaseService';

export default class MockService extends BaseService {
    login(password) {
        localStorage.setItem('authorized', JSON.stringify(password === '123'));

        return Promise.resolve(this.loggedIn());
    }

    loggedIn() {
        return JSON.parse(localStorage.getItem('authorized'));
    }

    decodeToken() {
        return {
            name: 'Chiquito',
            surname: 'Handepora'
        };
    }
}
