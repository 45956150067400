import React from 'react';
import './main-title.scss';

const MainTitle = props => {
    const getClassName = props => {
        const classNames = ['main-title'];

        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return <h1 className={getClassName(props)}>{props.children}</h1>;
};

export default MainTitle;
