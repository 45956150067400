import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import AppProvider from './context/AppProvider';
import PortalProvider from './context/PortalProvider';
import AppRoutes from './routes/AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/scss/main.scss';

const App = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <AppProvider>
                <PortalProvider>
                    <Router>
                        <AppRoutes />
                    </Router>
                </PortalProvider>
            </AppProvider>
        </I18nextProvider>
    );
};

export default App;
