import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import mockUserAvatar from './user.png';
import { I18nContext } from 'react-i18next';
import './user-widget.scss';

const UserWidget = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <Link to="/user-account">
            <div className="user-widget">
                <img className="user-widget__avatar"
                     src={mockUserAvatar}
                     alt="Chino Cudeiro"
                />
                {i18n.t('components.user_widget.title')}
            </div>
        </Link>
    );
};
export default UserWidget;
