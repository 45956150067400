import React from 'react';
import PrimaryCard from '../Card/PrimaryCard';
import NumberFormat from '../../NumberFormat/NumberFormat';
import './stats-card.scss';

const StatsCard = props => {
    return (
        <PrimaryCard className="stats-card">
            {props.title &&
            <header className="stats-card__header">
                {props.title}
            </header>}
            <div className="stats-card__contents">
                <div className="stats-card__big-box">
                    <NumberFormat className="stats-card__value"
                                  value={props.value}
                    />
                    <div className="stats-card__description">
                        {props.description}
                    </div>
                </div>
                <div className="stats-card__delta-box">
                    <NumberFormat className="stats-card__delta"
                                  value={props.delta}
                                  prefix={props.delta > 0 ? '+' : null}
                    />
                </div>
            </div>
        </PrimaryCard>
    );
};

export default StatsCard;

StatsCard.defaultProps = {
    title: '',
    description: '',
    value: 0,
    delta: 0
};
