import React from 'react';
import PlatformButton from './PlatformButton';
import logo from './youtube.svg';

const YoutubeButton = props => (
    <PlatformButton logo={logo}
                    bare={props.bare}
                    checked={props.checked}
                    onClick={props.onClick}
    />
);

export default YoutubeButton;
