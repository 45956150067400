import React, { Fragment, useContext } from 'react';
import { Formik, Form } from 'formik';
import InputField from '../Form/Fields/InputFIeld';
import { I18nContext } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../Button/Button';

const ChangePasswordForm = props => {
    const {i18n} = useContext(I18nContext);

    const validationSchema = Yup.object({
        password: Yup.string()
            .required(i18n.t('global.required')),
        repeatPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], i18n.t('global.passwords_must_match'))
    });


    Yup.object({
        password: Yup.string().required('Password is required'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    return (
        <Fragment>
            <Formik validationSchema={validationSchema}
                    initialValues={{
                        email: ''
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onSubmit(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <InputField label={i18n.t('components.change_password_form.new_password_placeholder')}
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                        />
                        <InputField label={i18n.t('components.change_password_form.repeat_net_password_placeholder')}
                                    name="repeatPassword"
                                    type="password"
                                    placeholder="Password"
                        />

                        <Button type="submit"
                                solid
                                gradient
                                block
                                disabled={props.isSubmitting || !props.isValid}
                                text={i18n.t('components.change_password_form.button')}
                        />
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};
export default ChangePasswordForm;

ChangePasswordForm.defaultProps = {
    onSubmit: () => {}
};
