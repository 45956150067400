import React, { useContext } from 'react';
import { I18nContext } from 'react-i18next';
import DarkCard from '../Card/DarkCard';
import Button from '../Button/Button';
import moment from 'moment';
import NumberFormat from '../../NumberFormat/NumberFormat';
import './payments-card.scss';

const PaymentsCard = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <DarkCard className="payments-card"
                  title={i18n.t('components.payments_card.title')}>
            <Button text={i18n.t('components.my_stats_card.button.check_stats')}
                    className="mb-5"
                    dark
                    to={'/payments'}
            />
            <div className="mb-2">
                {i18n.t('components.payments_card.your_last_payment')}
                &nbsp;
                {moment(1564679582000).format('DD/MM/YYYY')}
            </div>
            <div className="mb-2">
                {i18n.t('components.payments_card.total_payment')}
                &nbsp;
                {4}
            </div>
            <div className="mb-5">
                {i18n.t('components.payments_card.total_paid_amount')}
                &nbsp;
                <NumberFormat value={357.12}
                              suffix="€"
                />
            </div>
            <div className="payments-card__result">
                <span className="payments-card__big">357</span>,12€
            </div>
        </DarkCard>
    );
};

export default PaymentsCard;
