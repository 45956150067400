import React from 'react';
import logo from './streamion-gradient-logo.png';
import { Link } from 'react-router-dom';
import './streamion-gradient-brand.scss';

const StreamionGradientBrand = props => (
    <Link to="/login"
          className="streamion-gradient-brand">
        <img src={logo} alt="Streamion" width={140} />
    </Link>
);

export default StreamionGradientBrand;
