import React from 'react';
import './container.scss';

const Container = props => {
    const getClassName = props => {
        const classNames = ['container'];

        if ('narrow' in props) classNames.push('container--narrow');

        return classNames.join(' ');
    };

    return (
        <div className={getClassName(props)}>
            {props.children}
        </div>
    );
};
export default Container;
