import React from 'react';
import './chat-container.scss';

const ChartContainer = props => (
    <div className="char-container"
         style={props.style}>
        {props.children}
    </div>
);

export default ChartContainer;
