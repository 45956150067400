import React from 'react';
import './tertiary-title.scss';

const TertiaryTitle = props => {
    const getClassName = props => {
        const classNames = ['tertiary-title'];

        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return <h3 className={getClassName(props)}>{props.children}</h3>;
};

export default TertiaryTitle;
