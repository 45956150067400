import React, { useContext, useEffect } from 'react';
import { PortalContext } from '../../context/PortalProvider';

export const PortalOut = ({className, id}) => {
    const portalContext = useContext(PortalContext);

    useEffect(() => {
        return () => portalContext.unregisterPortalContainer(id);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const getClassName = className => {
        const classNames = ['portal-out'];

        if (className) classNames.push(className);

        return classNames.join(' ');
    };
    
    return (
        <div className={getClassName(className)}
             ref={container => portalContext.registerPortalContainer(id, container)}
             id={id}
        />
    );
};
