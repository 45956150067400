import React, { useContext, useState } from 'react';
import { I18nContext } from 'react-i18next';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import './stream-score.scss';
import MainTitle from '../MainTitle/MainTitle';
import NumberFormat from '../../NumberFormat/NumberFormat';
import Portal from '../Portal/Portal';

const StreamScoreWidget = props => {
    const [modalIsOpen, setModalOpen] = useState(false);
    const {i18n} = useContext(I18nContext);

    // TODO: Necesita trabajo para posicionar el valor current en casos límite 0 y 10.
    //       posicionarlo con left es muy trampas.
    return (
        <section className="stream-score">
            <header className="stream-score__header">
                <MainTitle>
                    {i18n.t('components.stream_score.title')}
                </MainTitle>
            </header>
            <div className="stream-score__bar-container">
                <div className="stream-score__scores">
                    <div className="stream-score__min">0</div>
                    <div className="stream-score__current"
                         style={{left: `${props.score * 10}%`}}>{props.score}</div>
                    <div className="stream-score__max">10</div>
                </div>
                <div className="stream-score__bar" />
            </div>
            <footer className="stream-score__footer">
                <div className="stream-score__benefit">
                    {i18n.t('components.stream_score.benefit')}
                    <NumberFormat className="stream-score__benefit-value"
                                  value={props.benefitPercent}
                                  suffix="%"
                    />
                </div>
                <div className="stream-score__actions">
                    <Button text={i18n.t('components.stream_score.cta')}
                            primary
                            small
                            onClick={event => setModalOpen(true)}
                    />
                </div>
            </footer>

            <Modal open={modalIsOpen}
                   title={i18n.t('components.stream_score.modal.improve_ranking.title')}
                   onClose={event => setModalOpen(false)}>
                Contenido del modal
                <Portal.In target="modal__actions">
                    <Button text={i18n.t('components.stream_score.modal.improve_ranking.button.cancel')}
                            onClick={event => setModalOpen(false)}
                    />
                    <Button text={i18n.t('components.stream_score.modal.improve_ranking.button.accept')}
                            solid
                            onClick={event => console.log('Botón desde el modal')}
                    />
                </Portal.In>
            </Modal>
        </section>
    );
};
export default StreamScoreWidget;

StreamScoreWidget.defaultProps = {
    benefitPercent: 20,
    score: 4
};
