import React from 'react';
import PropTypes from 'prop-types';
import './card.scss';

const Card = props => {
    const getClassName = props => {
        const classNames = ['card'];

        if (props.variant === 'primary') classNames.push('card--primary');
        if (props.variant === 'secondary') classNames.push('card--secondary');
        if (props.variant === 'dark') classNames.push('card--dark');
        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return (
        <article className={getClassName(props)}>
            {props.title
                ? <header className="card__header">
                    <h1 className="card__title">{props.title}</h1>
                </header>
                : null
            }
            <section className="card__contents">
                {props.children}
            </section>
        </article>
    );
};
export default Card;

Card.defaultProps = {
    variant: 'primary'
};

Card.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'dark'])
};
