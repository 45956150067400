import React from 'react';
import './circle.scss';

const Circle = props => {
    const getClassName = props => {
        const classNames = ['circle'];

        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return (
        <div className={getClassName(props)}>{props.children}</div>
    );
};

export default Circle;
