import React from 'react';
import { withAdminLayout } from '../../components/HOC/WithLayout';
import StreamScoreWidget from '../../components/StreamScoreWidget/StreamScoreWidget';
import LiveModeCard from '../../components/LiveModeCard/LiveModeCard';
import MyStatsCard from '../../components/MyStatsCard/MyStatsCard';
import PaymentsCard from '../../components/PaymentsCard/PaymentsCard';
import './dashboard-page.scss';

const DashboardPage = props => {
    return (
        <section className="dashboard-page">
            <div className="dashboard-page__header">
                <StreamScoreWidget />
            </div>
            <div className="dashboard-page__col1">
                <LiveModeCard />
            </div>
            <div className="dashboard-page__col2">
                <MyStatsCard />
            </div>
            <div className="dashboard-page__col3">
                <PaymentsCard />
            </div>
        </section>
    );
};

export default withAdminLayout(DashboardPage);
