import React from 'react';
import columns from './table-columns';
import data from './table-mocked-data';
import Table from '../Table/Table';

const LastPaymentsTable = props => (
    <Table className="last-payments-table"
           columns={columns}
           data={data}
    />
);

export default LastPaymentsTable;
