import React, { Fragment, useContext } from 'react';
import { Formik, Form } from 'formik';
import InputField from '../Form/Fields/InputFIeld';
import { I18nContext } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../Button/Button';

const RecoverPasswordForm = props => {
    const {i18n} = useContext(I18nContext);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(i18n.t('global.invalid_email'))
            .required(i18n.t('global.required'))
    });

    return (
        <Fragment>
            <Formik validationSchema={validationSchema}
                    initialValues={{
                        email: ''
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onSubmit(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <InputField label="email"
                                    name="email"
                                    type="email"
                                    placeholder="User"
                        />

                        <Button type="submit"
                                solid
                                gradient
                                block
                                disabled={props.isSubmitting || !props.isValid}
                                text={i18n.t('global.enter')}
                        />
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};
export default RecoverPasswordForm;

RecoverPasswordForm.defaultProps = {
    onSubmit: () => {}
};
