import React, { useContext, useState } from 'react';
import { I18nContext } from 'react-i18next';
import PrimaryCard from '../Card/PrimaryCard';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Portal from '../Portal/Portal';

const LiveModeCard = props => {
    const [modalIsOpen, setModalOpen] = useState(false);
    const {i18n} = useContext(I18nContext);

    return (
        <PrimaryCard title={i18n.t('components.live_mode_card.title')}>
            <Button text={i18n.t('components.live_mode_card.button.start_streaming')}
                    onClick={event => setModalOpen(true)}
            />
            <div className="my-5 white-space-pre-line">
                {i18n.t('components.live_mode_card.description')}
            </div>
            <Button text={i18n.t('components.live_mode_card.button.setup')}
                    solid
                    to={'/live-mode/setup'}
            />
            <Modal open={modalIsOpen}
                   title={i18n.t('components.live_mode_card.modal.start_streaming.title')}
                   onClose={event => setModalOpen(false)}>
                start streaming
                <Portal.In target="modal__actions">
                    <Button text={i18n.t('components.live_mode_card.modal.start_streaming.button.cancel')}
                            onClick={event => setModalOpen(false)}
                    />
                    <Button text={i18n.t('components.live_mode_card.modal.start_streaming.button.accept')}
                            solid
                            onClick={event => console.log('Botón desde el modal')}
                    />
                </Portal.In>
            </Modal>
        </PrimaryCard>
    );
};

export default LiveModeCard;
