import React from 'react';
import ReactResponsiveModal from 'react-responsive-modal';
import Portal from '../Portal/Portal';
import PropTypes from 'prop-types';
import './modal.scss';

const Modal = props => {
    const getClassName = props => {
        const classNames = ['modal'];

        if (props.size) classNames.push(`modal--size-${props.size}`);
        if (props.className) classNames.push(props.className);

        return classNames.join(' ');
    };

    return (
        <ReactResponsiveModal {...props}
                              classNames={{
                                  modal: getClassName(props),
                                  overlay: 'modal__overlay',
                                  closeButton: 'modal__close-button'
                              }}
                              open={props.open}
                              onClose={props.onClose}>
            <section className="modal__body">
                <div className="modal__header">
                    {!!props.title &&
                    <div className="modal__title">{props.title}</div>}
                </div>
                <div className="modal__contents">
                    {props.children}
                </div>
                <Portal.Out className="modal__footer"
                            id="modal__actions"
                />
            </section>
        </ReactResponsiveModal>
    );
};
export default Modal;

Modal.defaultProps = {
    size: 'big',
    open: false,
    onClose: () => {}
};

Modal.propTypes = {
    size: PropTypes.oneOf(['eager', 'jumbo', 'big', 'small', 'xs'])
};
