import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { I18nContext } from 'react-i18next';
import './secondary-navigation.scss';

const SecondaryNavigation = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <nav className="secondary-navigation">
            <NavLink className="secondary-navigation__link"
                     activeClassName="secondary-navigation__link--is-active"
                     to="/my-stats">
                {i18n.t('components.navigation.my_stats.title')}
            </NavLink>
            <NavLink className="secondary-navigation__link"
                     activeClassName="secondary-navigation__link--is-active"
                     to="/payments">
                {i18n.t('components.navigation.payments.title')}
            </NavLink>
            <NavLink className="secondary-navigation__link"
                     activeClassName="secondary-navigation__link--is-active"
                     to="/live-mode/setup">
                {i18n.t('components.navigation.settings.title')}
            </NavLink>
            <NavLink className="secondary-navigation__link"
                     activeClassName="secondary-navigation__link--is-active"
                     to="/user-account">
                {i18n.t('components.navigation.my_account.title')}
            </NavLink>
            <Link className="secondary-navigation__link"
                  to="/login">
                {i18n.t('components.navigation.logout.title')}
            </Link>
        </nav>
    );
};

export default SecondaryNavigation;
