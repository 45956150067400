const data = [
    {
        className: 'table__row--is-featured',
        status: 'a',
        date: 1581192784000,
        reference: '134725634',
        amount: 65.78
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 15.95
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 25.46
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 35.78
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 45.00
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 15.50
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 950.75
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 125.70
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 125.80
    },
    {
        status: 'b',
        date: 1581192784000,
        reference: '134725634',
        amount: 15.78
    }
];

export default data;
