import React from 'react';
import { Link } from 'react-router-dom';

const Brand = props => (
    <Link to="/">
        <svg width="65" height="42" viewBox="0 0 65 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5728 14.8656C10.5793 14.3411 7.52305 9.42949 6.77441 7.73636C6.76142 7.70714 6.71782 7.72424 6.72735 7.75472L8.3014 13.1481L9.43322 16.7623C9.45359 16.8048 9.47483 16.847 9.49692 16.889L10.309 19.6416C11.053 21.185 12.9518 22.3498 15.1673 22.6218L42.9129 26.0285L45.8052 26.3837L50.7131 26.9863L50.7544 27.0129C54.7478 27.5374 57.8041 32.4491 58.5527 34.1422C58.5657 34.1714 58.6093 34.1543 58.5998 34.1238L57.0258 28.7304L56.6935 27.6693L55.0935 22.2462C54.3495 20.7028 52.4507 19.538 50.2352 19.266L43.4123 18.4283L40.5199 18.0731L14.6142 14.8923L14.5728 14.8656Z" fill="white"/>
            <path d="M50.8666 8.33171C50.1307 6.75755 48.2434 5.57382 46.038 5.30303L39.2463 4.46911L36.3671 4.11559L4.68555 0.225586L6.27679 5.81444C7.01282 7.38869 8.90003 8.57241 11.1054 8.8432L38.7245 12.2344L41.6037 12.5879L52.458 13.9207L50.8666 8.33171Z" fill="white"/>
            <path d="M59.0317 35.6236C58.2961 34.0474 56.4089 32.8624 54.2036 32.5916L47.4118 31.7577L44.5326 31.4042L12.8511 27.5142L14.4414 33.1102C15.1772 34.6864 17.0643 35.8713 19.2697 36.1421L46.8888 39.5333L49.768 39.8869L60.6222 41.2196L59.0317 35.6236Z" fill="white"/>
        </svg>
    </Link>
);
export default Brand;
