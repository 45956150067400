import React, { Component } from 'react';
import MockService from '../services/MockService';

export const AppContext = React.createContext({});

export default class AppProvider extends Component {
    state = {
        services: {
            mock: new MockService()
        }
    };

    render() {
        return (
            <AppContext.Provider value={{...this.state}}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
