import React, { useContext, useState } from 'react';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import { withLoginManagementLayout } from '../../components/HOC/WithLoginManagementLayout';
import { Link } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';
import './login-page.scss';

const LoginPage = props => {
    const [wrongLogin, setWrongLogin] = useState(false);
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);

    const handleSubmit = ({email, password}) => {
        appContext.services.mock
            .login(password)
            .then(isLogged => {
                if (!isLogged) {
                    setWrongLogin(!isLogged);

                    return;
                }

                const from = props.location.state
                    ? props.location.state.from.pathname
                    : '/';
                props.history.push(from);
            });
    };

    return (
        <div className="login-page">
            <LoginForm onSubmit={handleSubmit} />
            {!!wrongLogin &&
            <div className="login-page__wrong-login">
                {i18n.t('Usuario y/o password incorrectos')}
            </div>}
            <Link className="login-page__recover-password"
                  to="recover-password">
                {i18n.t('pages.login.recover_password_link')}
            </Link>
            <div className="login-page__sign-up">
                {i18n.t('pages.login.sign_up.not_registered')}
                &nbsp;
                <Link to="sign-up">
                    <strong>{i18n.t('pages.login.sign_up.sign_up_link')}</strong>
                </Link>
            </div>
        </div>
    );
};

export default withLoginManagementLayout(LoginPage);
