import React, { Component } from 'react';
import Brand from '../Brand/Brand';
import UserWidget from '../UserWidget/UserWidget';
import './primary-navigation.scss';

export default class PrimaryNavigation extends Component {
    render() {
        return (
            <nav className="primary-navigation">
                <Brand />
                <UserWidget />
            </nav>
        );
    }
}
