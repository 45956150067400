import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { I18nContext } from 'react-i18next';
import ChartContainer from '../ChartContainer/ChartContainer';

const PaymentsChart = props => {
    const {i18n} = useContext(I18nContext);

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: i18n.t('components.payments_chart.dataset_a.title'),
                backgroundColor: 'rgba(0, 70, 145, 0.8)',
                hoverBackgroundColor: 'rgba(0, 70, 145, 1)',
                data: [65, 59, 80, 91, 56, 55, 40]
            },
            {
                label: i18n.t('components.payments_chart.dataset_b.title'),
                backgroundColor: 'rgba(9, 116, 195, 0.8)',
                hoverBackgroundColor: 'rgba(9, 116, 195, 1)',
                data: [59, 40, 50, 81, 51, 45, 65]
            },
            {
                label: i18n.t('components.payments_chart.dataset_b.title'),
                backgroundColor: 'rgba(66, 162, 229, 0.8)',
                hoverBackgroundColor: 'rgba(66, 162, 229, 1)',
                data: [49, 80, 81, 56, 40, 65, 55]
            }
        ]
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        },
        maintainAspectRatio: false
    };

    return (
        <ChartContainer style={{height: 300}}>
            <Bar data={data}
                 options={options}
            />
        </ChartContainer>
    );
};

export default PaymentsChart;
