import React, { useContext } from 'react';
import { withAdminLayout } from '../../components/HOC/WithLayout';
import SecondaryNavigation from '../../components/SecondaryNavigation/SecondaryNavigation';
import MainTitle from '../../components/MainTitle/MainTitle';
import { I18nContext } from 'react-i18next';
import './user-account-page.scss';

const UserAccountPage = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <section className="user-account-page">
            <div className="user-account-page__navigation">
                <SecondaryNavigation />
            </div>
            <div className="user-account-page__header">
                <MainTitle>
                    {i18n.t('pages.user_account.title')}
                </MainTitle>
            </div>
            <div className="user-account-page__contents">
                User…
            </div>
        </section>
    );
};

export default withAdminLayout(UserAccountPage);
