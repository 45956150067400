import React from 'react';
import PlatformButton from './PlatformButton';
import logo from './twitch.svg';

const TwitchButton = props => (
    <PlatformButton logo={logo}
                    bare={props.bare}
                    checked={props.checked}
                    onClick={props.onClick}
    />
);

export default TwitchButton;
