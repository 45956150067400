import React from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Icon from '../Icon/Icon';
import './contextual-menu.scss';

const ContextualMenu = props => (
    <div className="context-menu">
        <ContextMenuTrigger id={props.id} holdToDisplay={0}>
            {props.triggerComponent}
        </ContextMenuTrigger>

        <ContextMenu id={props.id}
                     className={props.bare ? 'react-contextmenu--bare' : ''}>
            {props.menuItems.map((menuItem, id) => (
                <MenuItem key={id}
                          {...menuItem.props}>
                    {menuItem.component}
                </MenuItem>
            ))}
        </ContextMenu>
    </div>
);

export default ContextualMenu;

ContextualMenu.defaultProps = {
    id: 'contextual-trigger',
    triggerComponent: <Icon icon="ellipsis-v" />,
    menuItems: []
};
