import React from 'react';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import Icon from '../Icon/Icon';
import Circle from '../Circle/Circle';
import NumberFormat from '../../NumberFormat/NumberFormat';
import moment from 'moment';

const statusRenderers = {
    a: props => (<Circle><Icon icon="bell" /></Circle>),
    b: props => (<Circle><Icon icon="check" /></Circle>)
};

const columns = [
    {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        Cell: ({row}) => {
            return (row.values.status in statusRenderers)
                ? statusRenderers[row.values.status]({value: row.values.status})
                : null
        },
    },
    {
        Header: 'Date',
        accessor: 'date',
        Cell: ({row}) => {
            return (
                moment(row.values.date).format('DD/MM/YYYY hh:mm:ss')
            );
        }
    },
    {
        Header: 'Reference',
        accessor: 'reference',
    },
    {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({row}) => {
            return (
                <NumberFormat value={row.values.amount}
                              suffix="€"
                />
            );
        }
    },
    {
        Header: () => null,
        accessor: 'actions',
        Cell: ({row}) => (
            <ContextualMenu id={`contextual-menu_${row.id}`}
                            menuItems={[
                                {
                                    component: <div>Context option 1</div>,
                                    props: {
                                        onClick: event => console.log('click opt 1', event)
                                    }
                                },
                                {
                                    component: <div>Context option 2</div>,
                                    props: {
                                        onClick: event => console.log('click opt 2', event)
                                    }
                                }
                            ]}
            />
        ),
    }
];

export default columns;
