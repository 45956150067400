import React, { useContext, useState } from 'react';
import { I18nContext } from 'react-i18next';
import PrimaryCard from '../Card/PrimaryCard';
import Button from '../Button/Button';
import PlatformToggler from '../PlatformToggler/PlatformToggler';
import './check-overlay-card.scss';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import TertiaryTitle from '../TertiaryTitle/TertiaryTitle';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

const CheckOverlayCard = props => {
    const [platform, setPlatform] = useState(null);
    const {i18n} = useContext(I18nContext);

    const togglePlatform = (platformName, checked) => {
        const currentPlatform = checked ? platformName : null;
        setPlatform(currentPlatform);
    };

    return (
        <PrimaryCard className="check-overlay-card">
            <header className="check-overlay-card__header">
                <Link className="check-overlay-card__back"
                      to="/">
                    <Icon icon="angle-left" /> {i18n.t('global.back')}
                </Link>
            </header>
            <SecondaryTitle>
                {i18n.t('components.check_overlay_card.step_1.title')}
            </SecondaryTitle>
            <PlatformToggler className="my-5 check-overlay-card__platforms"
                             checked={platform}
                             onToggle={togglePlatform}
            />
            <SecondaryTitle>
                {i18n.t('components.check_overlay_card.step_2.title')}
            </SecondaryTitle>
            <div className="check-overlay-card__actions mt-3">
                <div>
                    <TertiaryTitle className="mb-3">
                        {i18n.t('components.check_overlay_card.overlay_url.description')}
                    </TertiaryTitle>
                    <Button text={i18n.t('components.check_overlay_card.overlay_url.action')}
                            light
                            solid
                            onClick={event => console.log('overlay_url copied to clipboard')}
                    />
                </div>
                <div>
                    <TertiaryTitle className="mb-3">
                        {i18n.t('components.check_overlay_card.overlay_url.description')}
                    </TertiaryTitle>
                    <Button text={i18n.t('components.check_overlay_card.check_overlay.action')}
                            solid
                            dark
                            onClick={event => console.log('se lanza anuncio')}
                    />
                </div>
            </div>
        </PrimaryCard>
    );
};

export default CheckOverlayCard;
