import React, { useContext } from 'react';
import { I18nContext } from 'react-i18next';
import SecondaryCard from '../Card/SecondaryCard';
import Button from '../Button/Button';

const MyStatsCard = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <SecondaryCard title={i18n.t('components.my_stats_card.title')}>
            <Button text={i18n.t('components.my_stats_card.button.check_stats')}
                    className="mb-5"
                    secondary
                    to={'/my-stats'}
            />
            <div className="mb-2">
                {i18n.t('components.my_stats_card.your_last_streaming')}
                &nbsp;
                {i18n.t('global.viewers_value', {count: 219})}
            </div>
            <div className="mb-2">
                {i18n.t('components.my_stats_card.subscribes')}
                &nbsp;
                {i18n.t('global.subscribes_value', {count: 181})}
            </div>
            <div>
                {i18n.t('components.my_stats_card.stream_title')}
                &nbsp;
                {'"Challenge TFT 12 horas!"'}
            </div>
        </SecondaryCard>
    );
};

export default MyStatsCard;
